@import "../../../styles/base/variable.scss";

.banner-card{
  padding:32px 0 38px 0;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  color: #222549;

  &-header{
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    font-weight: 500;

  }

  &-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  &-body{
    display:flex;
    margin-top: 29px;
    padding-left: 12px;
    padding-right: 12px;
    .banner-card-item{
      text-align: center;
      flex:1;
      h2{
        font-size: 14px;
        font-weight: 400;
        color:#8EA6BF;
        display: flex;
        justify-content: center;
      }
    }
  }

  &-footer{
    position: absolute;
    bottom: 8px;
    right: 16px;

  }

  &.banner-card-link{
    cursor: pointer;
    transition: all 0.7s ease;
    &:hover{
      box-shadow: 0px 9px 28px 0px rgba(26, 126, 227, 0.05), 0px 6px 16px 0px rgba(26, 126, 227, 0.08), 0px 3px 6px -4px rgba(26, 126, 227, 0.12);
    }
  }
}

.card-item-text{
  font-size: $font-size-lg;
  line-height: 1rem;
}
.card-item-unit{
  font-size: $font-size-sm;
  color:$text-color-secondary;
  margin-left: $margin-sm;
}
