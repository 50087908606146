$text-color-secondary: #8EA6BF;

.tableEmptyPlaceholder {
    width: 100%;
    font-size: 14px;
    padding-top: 60px;
    padding-bottom: 30px;
    background-repeat: no-repeat;
    background-size: 226px 150px;
    background-origin: content-box;
    background-position: center top;
    color: $text-color-secondary;

    .tableEmptyText {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 166px;
    }
}
