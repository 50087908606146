@import './variable';
@mixin respond-to($breakpoint-name) {
  @if $breakpoint-name == xs {
    @media (max-width: map-get($breakpoints, 'xs')) {
      @content;
    }
  } @if $breakpoint-name == mxs {
    @media (max-width: map-get($breakpoints, 'mxs')) {
      @content;
    }
  }  @else if $breakpoint-name == sm {
    @media (min-width: map-get($breakpoints, 'sm')) {
      @content;
    }
  } @else if $breakpoint-name == md {
    @media (min-width: map-get($breakpoints, 'md')) {
      @content;
    }
  } @else if $breakpoint-name == lg {
    @media (min-width: map-get($breakpoints, 'lg')) {
      @content;
    }
  } @else if $breakpoint-name == xlg {
    @media (min-width: map-get($breakpoints, 'xlg')) {
      @content;
    }
  } @else if $breakpoint-name == max-sm {
    @media (max-width: map-get($breakpoints, 'sm')) {
      @content;
    }
  } @else if $breakpoint-name == max-md {
    @media (max-width: map-get($breakpoints, 'md')) {
      @content;
    }
  } @else if $breakpoint-name == max-lg {
    @media (max-width: map-get($breakpoints, 'lg')) {
      @content;
    }
  }
}
@mixin generateClassName($pre,$attr,$props){
  @each $var in $props {
    .#{$pre}-#{$var}{
      #{$attr}:$var
    }
  }
 }

