 .seq {
     display: inline-flex;
     font-size: 16px;
     font-weight: 500;
     padding-right: 12px;
     color: #fff;
     width: 44px;
     height: 28px;
     justify-content: center;
     vertical-align: 2px;
     margin-right: 8px;
     background: url('../../../images/version2022/img_step.svg');
 }