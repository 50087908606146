.header-right-user {
    padding: 0 32px;
    margin-right: 20px;
    height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #333333;
    border-right: 1px solid #F1F4F7;

    &.active{
        .header__user-dropdown-trigger{
            color: #1070D0;
        }
    }

    &:hover{
        .header__user-dropdown-trigger{
            color: #1070D0;
        }

        &.readonly{
             cursor: default;
        }
    }

   

    .anticon {
        margin-left: 10px;
    }
    button,
    a {
        background-color: transparent;
        border: 0;

        &:hover,
        &:active,
        &:focus {
            opacity: .7;
            cursor: pointer;
            outline: none;
        }
    }
}

.header__user-dropdown {
    z-index: 999;
}

.header__user-content {
    width: 650px;
    background: #fff;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.04);
    border: 1px solid #EBF1F7;
    border-radius: 4px;
    padding: 18px 20px;

    .user-content__title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #F3F3F3;
        padding-bottom: 4px;

        h2 {
            font-size: 18px;
        }
    }

    .user-content__title,
    .user-content__help-tip,
    .user-content__btns {
        margin-bottom: 10px;
    }

    .user-content__btns {
        display: flex;
        justify-content: space-between;
        align-items: center;

       
    }

    .user-content__user-list {
        max-height: 500px;
        overflow-y: auto;
    }

    .sub-account-btn{
        border-radius: 21px;
        padding: 0 10px;
        min-width: 88px;
        height: 28px;
        font-size: 12px;

        &.ghost{
            border: 1px solid #3B79C1;
            background-color: #fff;
            color: #3B79C1;
            text-shadow: none;
        }

        &.ant-switch::after{
            width: 24px;
            height: 24px;
        }

        &.ant-btn-primary{
            box-shadow: none;
        }
    }
}