.betaIcon {
    background: #8EA6BF;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 41px;
    height: 20px;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 400;


    &.sm {
        width: 29px;
        height: 16px;
        border-radius: 2px;
        .ultr01Font {
            transform: scale(0.83);
        }
    }

}