@import "../../../styles/base/variable.scss";
.loading-wrap{
  position: relative;

  .spin-holder{
    display:block;
    position: absolute;
    height: 100%;
    max-height: 400px;
    width: 100%;
    z-index: 5;
    opacity: 1;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .loading-content-container{
    pointer-events: none;
    user-select: none;
    overflow: hidden;
    opacity: 0.5;
    transition: opacity 0.3s;
  }
  .anticon{
    color: $primary-color;
  }

}