.notFound{
    width: 100%;
    height: inherit !important;
    background-color: #fff;
    border-radius: 6px;
    padding-top: 88px;
    color: #8EA6BF;
    font-size: 14px;

}
.img{
    width: 226px;
    height: 148px;
    margin-bottom: 16px;
}
