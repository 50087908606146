.pagination {
    text-align: center;
    position: relative;

    .simple-total {
        position: absolute;
        z-index: 99;
        left: 58%;
        top: 50%;
        margin-top: -11px;
        font-size: 14px;
    }

    // 兼容改版
    &.pagination-upgrade {
        .ant-pagination-item,
        .ant-pagination-prev,
        .ant-pagination-next {
            min-width: 36px;
            height: 36px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 36px;
            background-color: #fff;
            border: 1px solid rgba(#999999, 0.2);
            overflow: hidden;

            &:hover {
                border: 1px solid #1A7EE3;

                a {
                    color: #1A7EE3;
                }
            }
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: 1px solid rgba(#999999, 0.2);
        }

        .ant-pagination-disabled {
            &:hover {
                border: 1px solid rgba(#999999, 0.2);

                a {
                    color: rgba(0, 0, 0, 0.25);
                }
            }
        }

        .ant-select-item {
            background: #fff !important;
            border: none !important;
            padding-bottom: 0px;

            &:last-child {
                margin-bottom: 10px;
            }

            &-option-selected {
                .ant-select-item-option-content {
                    border: 1px solid #F1F4F7;
                    background: #F9FBFD !important;
                    color: #0A66C2;
                }
            }

            &-option-content {
                border-radius: 18px;
                border: 1px solid #fff;
                height: 36px;
                font-size: 14px;
                margin: 0;
                display: flex;
                align-items: center;

                &:hover {
                    border: 1px solid #F1F4F7;
                    background: #F9FBFD;
                    color: #0A66C2;
                }
            }
        }

        .ant-pagination-item.ant-pagination-item-active {
            border: none;
            background: #1A7EE3;

            &:hover {
                background-color: #1070D0;

                a {
                    color: #fff;
                }
            }
        }

        .ant-pagination-item-link {
            line-height: 36px;
        }

        .ant-pagination .ant-select-selection--single {
            height: 36px;
            border-radius: 999px;
            min-width: 90px;

            .ant-select-selection__rendered {
                line-height: 36px;
            }
        }
    }
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}
