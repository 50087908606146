.collector {
    display: flex;
    align-items: flex-start;
    color: #222549;

    .collectorTitle {
        font-weight: 500;
        width: 100px;
    }

    .collectorItems {
        display: inline-flex;
        flex: 1;
        cursor: pointer;
        flex-wrap: wrap;
        margin-top: -16px;
        .wrapper {
            padding-top: 16px;
            &.disabled {
                opacity: 0.4;
                cursor: not-allowed;
                .collectorItem {
                    pointer-events: none;
                }
            }
        }


        .collectorItem {
            transition: all .3s ease;

        }
    }

}