@import "./variable.scss";

$active-color: #2EA8ED;

body {
    .ant-carousel {
        .slick-dots {
            height: 8px;

            li {
                background-color: #ccc;
                width: 8px;
                height: 8px;
                border-radius: 100%;

                button {
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    opacity: 0;
                }

                &.slick-active {
                    background-color: $active-color;
                }

                &.slick-active button {
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background-color: $active-color;
                }

                &:hover {
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background-color: $active-color;
                }
            }
        }
    }

    .rc-virtual-list {
        box-shadow: 0px 9px 28px 0px rgba(26, 126, 227, 0.05), 0px 6px 16px 0px rgba(26, 126, 227, 0.08), 0px 3px 6px -4px rgba(26, 126, 227, 0.12);
        filter: blur(0px);
        cursor: pointer;
    }


    // ant-table style reset
    .ant-table-thead>tr>th {
        background-color: #fff;
        font-weight: 400;
        color: #8EA6BF;
    }

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
        padding: 10px 12px;

        &.ant-table-selection-column {
            padding-right: 0;
            padding-left: 0;
        }
    }

    tr {
        td.ant-table-cell {
            font-size: 14px;
            color: #222549 !important;
        }
    }

    .ant-table-wrapper {
        .ant-table-thead>tr>th.ant-table-column-has-sorters {
            background: #fff;
        }

        .ant-table-column-sorters {
            display: inline;
            user-select: none;
            vertical-align: middle;

            .ant-table-column-sorter-inner {
                transform: translateY(-3px);
            }
        }

        .ant-table-column-sorter-up,
        .ant-table-column-sorter-down {
            svg {
                color: #8EA6BF;
            }

            &.active {
                svg {
                    color: #1A7EE3;
                }
            }
        }

        .ant-table-tbody>tr>td.ant-table-column-sort {
            background: none;
        }

        .ant-table-tbody>tr:hover>td.ant-table-column-sort {
            background: #F9FBFD !important;
        }

        .ant-table-tbody>tr.ant-table-row-selected td {
            background: #F9FBFD !important;
        }
    }

    .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters,
    .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters {
        padding-right: 18px !important;
    }

    .ant-table-tbody {
        .ant-table-row {
            td:first-child {
                border-left: 2px solid transparent;
            }

            td {
                border-bottom: 1px solid #F1F4F7;
            }
        }

        .ant-table-row:hover {
            td:first-child {
                border-left: 2px solid #1A7EE3;
            }
        }
    }

    // pagination
    $page-item-height: 24px;

    %page-item {
        min-width: $page-item-height;
        height: $page-item-height;
        line-height: $page-item-height;
        border: none;
        margin-right: 6px;
    }

    .ant-pagination-item {
        @extend %page-item;

        &.ant-pagination-item-active {
            background-color: $secondary-color;

            a {
                color: #fff;
            }
        }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        @extend %page-item;

        .ant-pagination-item-link {
            border: none;
        }
    }

    .ant-pagination-total-text {
        height: $page-item-height + 2px;
        line-height: $page-item-height + 2px;
    }

    .ant-pagination {
        .ant-select-dropdown {
            box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.06);
        }

        .ant-select-selection--single {
            height: $page-item-height + 2px;
        }

        .ant-select-selection__rendered {
            line-height: $page-item-height + 2px;
        }

        .ant-select-dropdown-menu-item {
            padding: 8px 20px;
            border: 1px solid transparent;

            &:hover {
                background-color: #f9fbfd;
                border: 1px solid #f1f4f7;
            }

            &-selected,
            &-active {
                background-color: unset;
                border: unset;
                font-weight: 400;
                border: 1px solid transparent;
            }
        }
    }

    .ant-pagination-options-quick-jumper {
        height: $page-item-height + 2px;
        line-height: $page-item-height + 2px;

        input {
            height: $page-item-height + 2px;
            padding: 2px 10px;
        }
    }

    .ant-pagination-options-size-changer.ant-select {
        margin-right: 0;
    }

    // ant icon
    .anticon {
        user-select: none;
    }

    // ant btn
    .ant-btn {
        &.ant-btn-primary {
            &:disabled {
                color: #FFFFFF;
                background: #1A7EE3;
                opacity: 0.4;
            }
        }
    }

    // ant modal
    .ant-modal-header {
        background-color: #fff;
        padding: 30px 20px 0px 20px;
        border-bottom: none;
        border-radius: 10px 10px 0 0;

        .ant-modal-title {
            color: #222549;
            font-size: 18px;
        }
    }

    .ant-modal-content {
        border-radius: 10px;
        box-shadow: 0px 10px 10px 0px rgba(60, 70, 81, 0.1);
        border: 1px solid #EFF2F5;
    }

    .ant-modal-body {
        padding: 30px 40px 20px 40px;
    }

    .ant-modal-footer {
        padding: 10px 20px 30px 20px;
        border-top: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .modal-hide-cancel {
        .ant-modal-footer {
            .ant-btn:not(.ant-btn-primary) {
                display: none;
            }
        }
    }

    .modal-hide-ok {
        .ant-modal-footer {
            .ant-btn-primary {
                display: none;
            }
        }
    }

    // ant btn
    .ant-btn-two-chinese-chars>*:not(.anticon) {
        margin-right: unset;
        letter-spacing: 0.01em;
    }

    // ant dropdown
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        background: #f3f4f7;
    }

    // ant select
    .ant-select {
        .ant-select-arrow {
            svg {
                color: #8EA6BF;
                transform: scaleX(1.1);
            }
        }
    }

    // ant select menu
    .ant-select-dropdown {
        padding: 0;
        box-shadow: 0px 9px 28px 0px rgba(26, 126, 227, 0.05), 0px 6px 16px 0px rgba(26, 126, 227, 0.08), 0px 3px 6px -4px rgba(26, 126, 227, 0.12) !important;
    }

    .ant-select-item-option-content {
        padding-left: 10px;
        display: flex;
        align-items: center;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        // border: none;
        border: 1px solid #97B5D1;
        border-radius: 4px;
    }

    .ant-select-focused:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: none !important;
        border: 1px solid #1A7EE3 !important;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        font-weight: normal;
        background-color: #fff;
    }

    .ant-select-item-option-active {
        font-weight: normal;
    }

    .ant-select-item-option-selected {
        color: #0A66C2 !important;
        font-weight: normal;
    }

    .ant-select-dropdown-menu-item:hover,
    .ant-select-dropdown-menu-item-active {
        background-color: $list-hover-bg;
    }

    .ant-menu-title-content {
        overflow: visible !important;
    }

    // tooltip
    .ant-tooltip.auto-tooltip-width {
        max-width: none;
    }

    .ant-tooltip-inner {
        padding: 14px 16px;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 6px;
    }

    // card
    .ant-card {
        border-radius: $border-radius-base;

        .ant-card-head {
            border-radius: $border-radius-base $border-radius-base 0 0;
        }
    }

    // popover
    .ant-popover-inner {
        box-shadow: 0px 9px 28px 8px rgba(26, 126, 227, 0.05), 0px 6px 16px 0px rgba(26, 126, 227, 0.08), 0px 3px 6px -4px rgba(26, 126, 227, 0.12);
        border-radius: 6px;
        background-color: #fff;
    }

    //checkbox
    .ant-checkbox-wrapper {
        color: $text-color-primary;
    }

    .ant-checkbox-inner {
        border-radius: 2px;
        border: 1px solid #8EA6BF;
    }

    .ant-checkbox-disabled {
        .ant-checkbox-inner {
            background-color: #EFF2F5;
            border-color: #8EA6BF !important;
        }

        &.ant-checkbox-checked .ant-checkbox-inner::after {
            border-color: #8EA6BF;
        }
    }

    // ant input
    .ant-input {
        border-radius: 4px;
        border: 1px solid #8EA6BF;
        line-height: 2;
        height: 40px;

        &:hover,
        &:focus {
            border: 1px solid #1A7EE3;
            box-shadow: none;
        }

        &.ant-input-sm {
            height: 32px;
        }
    }

    .ant-input-affix-wrapper {
        padding-top: 0;
        padding-bottom: 0;
        border: 1px solid #8EA6BF;
        border-radius: 4px;
        overflow: hidden;

        &:focus,
        &-focused {
            box-shadow: none;
        }

        .ant-input {
            border: none;

            &:hover,
            &:focus {
                border: none;
            }

            &.ant-input-sm {
                height: 30px;
            }
        }
    }
}

.ant-message {
    .ant-message-custom-content {
        display: flex;

        &.ant-message-success {
            svg {
                color: #1A7EE3;
            }
        }

        &.ant-message-error {
            svg {
                color: #F24646;
            }
        }
    }

    .anticon {
        top: 3px;
    }
}

.address-earning-tooltip {
    .ant-popover-inner-content {
        padding-right: 4px;
    }
}

//ant tabs
.ant-tabs {
    .ant-tabs-tab {
        padding-top: 0;
        padding-bottom: 8px;
        font-size: 18px;
        font-weight: 500;

        .ant-tabs-tab-btn {
            color: #8EA6BF;
            text-shadow: none;
        }
    }

    .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            color: #222549;
        }
    }

    &.ant-tabs-top>.ant-tabs-nav::before {
        border-bottom: none;
    }

    // 不活跃tab 不置灰的另一种样式
    &.not-dim {
        .ant-tabs-tab {
            font-size: 20px;
        }

        .ant-tabs-tab .ant-tabs-tab-btn {
            color: #222549;
        }

        .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #1A7EE3;
        }
    }
}

.ant-tabs-ink-bar {
    height: 4px !important;
    border-radius: 2px;
}

//ant badge
.ant-badge-status-text {
    color: #222549;
}

//ant message
.ant-message-notice-content {
    border-radius: 4px;
}

.ant-checkbox-indeterminate {
    .ant-checkbox-inner::after {
        background-color: #fff;
    }
}