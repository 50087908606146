.root {
   
    &.withSymbol {
        padding-left: 10px;
        position: relative;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            width: 4px;
            height: 18px;
            position: absolute;
            left: 0;
            background: #1A7EE3;
        }

    }
}