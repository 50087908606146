%reset {
    border-radius: 999px !important;
    border: none !important;
    box-shadow: none;
}

%focusBorder {
    border: 1px solid #1A7EE3;
}

.searchInput {
    display: inline-block;

    :global {
        .ant-input-group-wrapper {
            border-radius: 999px;
            border: 1px solid #97B5D1;
            transition: all 0.3s;
            background-color: #fff;
            overflow: hidden;

            &:focus,
            &:hover {
                @extend %focusBorder;
            }
        }

        .ant-input-group-addon {
            @extend %reset;
            background-color: transparent;
            padding: 0 0 0 16px;
        }

        .ant-input-affix-wrapper {
            @extend %reset;
            padding: 0 8px;

            .ant-input{
                height: 32px;
                border: unset;
                box-shadow: unset;
            }
        }

        .anticon.ant-input-clear-icon{
            color: #CED6E4;
            &:hover{
                color:darken(#CED6E4, 10%)
            }
        }

    }

    &.focused {
        :global {
            .ant-input-group-wrapper {
                @extend %focusBorder;
            }
        }
    }
}