@import "../../../styles/base/variable.scss";
@import "../../../styles/base/upgrade-variable.scss";

@mixin decorator($width, $height, $left:unset, $top:unset, $right:unset, $bottom:unset) {
    position: absolute;
    left: $left;
    top: $top;
    right: $right;
    bottom: $bottom;
    border-radius: 100%;
    content: '';
    width: $width;
    height: $height;
    background: linear-gradient(270deg, #FFFFFF 0%, #E9F4FE 100%);
    opacity: 0.4;
}

.btc-card {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;

  &.noMarginBottom {
    margin-bottom: 0;
  }

  &.show-decorator {
    &::before{
      @include decorator(55px, 55px,50%,20%,);
    }

    &::after{
      @include decorator(200px, 200px,unset,unset,-20px, -95px);
    }
  }

  &-header {
    display: flex;
    padding-left: 24px;
    padding-top: 30px;
    padding-right: 24px;
    align-items: center;

    .btc-card-title {
      flex: 1;
      font-size: 20px;
      line-height: 1rem;
      font-weight: 500;
      color: #222549;

    }

    .btc-card-extra {
      float: right;
      font-size: 14px;
    }


  }

  &.btc-card-ghost {
    border: 1px solid $line-color;
    background: #F6F9FC;
    border-radius: 6px;
    border: 1px solid #E9F4FE;
    margin-bottom: 0;

    .btc-card-header {

      .btc-card-title {
        padding-left: 10px;
        position: relative;

        &::before {
          content: '';
          width: 2px;
          height: 14px;
          background: #1A7EE3;
          border-radius: 1px;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

  }

  &-body {
    padding: 30px 24px;
    position: relative;
    z-index: 2;
  }

  &.btc-card-sm {
    .btc-card-header {
      padding-top: 24px;
      padding-left: 16px;
      padding-right: 16px;


      .btc-card-title {
        font-size: 14px;
      }

      .btc-card-extra {}
    }

    .btc-card-body {
      padding: 24px 16px;
    }
    
  }


}

.card-form-item {
  font-size: 14px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 24px;
  color: #222549;
  line-height: 1rem;

  .btc-card-sm & {
    margin-bottom: 16px;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0px;
  }


  &.larget-text {
    font-size: 16px
  }

  dt {
    text-align: left;
  }

  dd {
    flex: 1;
    margin: 0;

    .suffix {
      margin-left: 4px;
    }
  }

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 2px;
    padding: 8px 16px;
    // border-left: 1px solid #1A7EE3;

    dt {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
    }

    dd {
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
  }




}