@import "styles/base/variable";

.header-right-setting {
    .ant-dropdown-link {
        color: #333333;

        &:focus {
            text-decoration: none;
        }

        .anticon {
            display: none;
        }
    }
}

.setting-dropdown-container {
    border-radius: 4px;

    .ant-menu {
        border-radius: 4px;
    }

    // ant menu reset
    .ant-menu {
        border-right: 0;

        &>.ant-menu-item,
        &>.ant-menu-item-selected {
            height: 40px;
            line-height: 40px;
            text-align: center;
            background-color: #fff;
            margin-bottom: 0;

            &.delete {
                color: #CC3300;
            }

            &>a {
                color: $text-color-default;
            }

            &:hover {
                background-color: $list-hover-bg;

                &>a {
                    color: $text-color-default;
                }
            }
        }
    }

    .ant-menu-inline .ant-menu-item {
        margin: 0;
        width: 100%;

        &:after {
            transition: none;
        }
    }

    .setting-content {
        background-color: #fff;
        border-radius: 6px;
        // min-width: 315px;
        box-shadow: 0px 9px 28px 0px rgba(26, 126, 227, 0.05), 0px 6px 16px 0px rgba(26, 126, 227, 0.08), 0px 3px 6px -4px rgba(26, 126, 227, 0.12);


        .triangel {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            right: 36px;
            top: -10px;
            width: 0;
            height: 0;
            border: 10px solid transparent;
            border-bottom-color: #fff;
            z-index: 1000;
        }

        &.nav-settings-menu {
            .ant-menu {
                .ant-menu-item {
                    justify-content: flex-start;
                }
            }
        }

        .address-info {
            padding: $padding-sm $padding-md 0 $padding-md;
            cursor: default;

            p {
                font-size: 12px;
                line-height: 22px;
            }
        }

        .ant-menu {
            padding: 20px;
            filter: blur(0px);

            .ant-menu-item {
                border-radius: 18px;
                border: 1px solid #fff;
                height: 36px;
                font-size: 14px;
                margin-bottom: 8px !important;
                margin: 0;
                display: flex;
                align-items: center;

                &:hover {
                    border: 1px solid #F1F4F7;
                    background: #F9FBFD;
                    color: #0A66C2;
                }
            }
        }
    }

    // 在子账户管理页面重新设置大小
    &.manage-account-setting {
        .setting-content {
            min-width: auto;
        }
    }
}

a.setting-menu-active {
    color: $text-color-active;
}

.setting-menu-icon {
    color: #8EA6BF;

    &:hover {
        color: $text-color-active;
    }
}

.setting-content {
    .address-info {
        .address-link {
            padding: 0;
            font-size: 12px;
            color: #1070D0;
        }
    }
}

.wrongIcon {
    height: 22px;
    width: 22px;
    margin-left: -10px;
}

.deleteName {
    font-size: 14px;
    font-weight: 400;
    color: #8EA6BF;
    line-height: 21px;
    margin-bottom: 24px;
}

.subAccountName {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #222549;
    line-height: 21px;
}

.deleteinfo {
    font-size: 14px;
    font-weight: 400;
    color: #F24646;
    line-height: 21px;
}

.cannotDelete {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    margin-bottom: 12px;
    margin-left: 16px;
}

.deleteCondition {
    margin-left: 48px;
    font-size: 14px;
    font-weight: 400;
    color: #8EA6BF;
    line-height: 22px;
}

.cannotDeleteReason {
    margin-left: 48px;
    font-size: 14px;
    font-weight: 400;
    color: #8EA6BF;
    line-height: 22px;
}