@import "../../../styles/base/variable.scss";

.ant-modal-header {
    color: $text-color-primary;
    font-size: $font-size-lg;
    height: $modal-header-height;
    vertical-align: middle;
    background-color: $card-header-bg;
    padding-top: $padding-md;
}

.ant-modal-footer {
    .ant-btn {
        height: $modal-footer-height;
        min-width: 100px;
        height: 40px;
        background: #1A7EE3;
        border-radius: 99px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &:hover{
            background: #1070D0;
        }

        &:not(.ant-btn-primary) {
            color: #1A7EE3;
            border: 1px solid #1A7EE3;
            background-color: #fff;
            &:hover{
                background: #1070D0;
                color:#fff;
            }
        }

        &.ant-btn-primary{
            margin-left: 16px;
        }
    }

}