@import "../../../styles/base/variable";
.btc-radion-button-group{
  color:$text-color-primary;
  font-size: $font-size-lg;
}

.btc-radion-button-item{
  display: inline-block;
  min-width: 108px;
  width:fit-content;
  height: 32px;
  background-color: $chunk-color;
  margin-left:4px;
  margin-right: 4px;
  margin:0 4px 10px 4px;
  font-weight: 400;
  border-radius: $border-radius-base;
  line-height: 31px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  border:0;
  box-shadow: none;
  cursor: pointer;



  &:hover{
    background-color: $link-hover-color;
    color:#fff;
  }

  &.active,&:focus{
    color:#fff;
    background-color: $primary-color;
    outline: none;
  }

  &.disabled{
    color:$text-color-disable;
    background-color: #f5f5f5;
    //pointer-events: none;
    cursor: not-allowed;

  }
}