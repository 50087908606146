.container {
    width: 118px;
    height: 32px;
    background-color: #f1f4f7;
    display: flex;
    border-radius: 2px;
    padding: 10px;
    cursor: pointer;
    padding: 3px;

    &:lang(de) {
        width: 128px;
    }

    &:global {
        &.sm {
            width: 100px;
            height: 24px;
            font-size: 11px;

            &:lang(de) {
                width: 110px;
            }
        }
    }

    .buttonItem {
        flex: 1;
        text-align: center;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;

        .title {
            z-index: 1;
            color: #8EA6BF;

            &.current {
                color: #222549;
            }
        }
    }


    .active {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background: #fff;
        width: 100%;
        z-index: 0;
    }


}