.not-dim {
    height: 0;
    :global{
        .ant-tabs-nav-list {
            height: 0;
        }
    }
}
.btn-active {
    color: #1A7EE3 !important;
    outline: none;
}

.radiotab {
    display: 'flex';
    justify-content: 'flex-end';
    .btn-toggle {
        margin-right: 6px;
        padding: 1px;
        border: 1px solid #2889fc;
        border-radius: 22px;
        .btn {
            margin: 2px;
            cursor: pointer;
            display: inline-block;
            padding: 0;
            border-radius: 22px;
            background-image: none;
            background-color: transparent;
            min-width: 56px;
            line-height: 18px;
            color: #5d5d84;
            text-align: center;
            font-size: 12px;
            .logo {
                height: 22px;
                padding: 2px;
                padding-right: 4px;
            }
            .text {
                vertical-align: middle;
            }
        }
        .btn:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .btn:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .btn.active {
            border-radius: 22px;
            background: #2889fc;
            color: #fff;
        }
        .btn:focus {
            box-shadow: none;
        }
    }
}