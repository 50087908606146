@import "../../styles/base/variable.scss";

$link-color: #0A66C2;
$transition-hover: 0.1s all ease-in;

.active-color {
    color: $link-color;
}

.header {
    // background: #ffffff;
    position: relative;
    z-index: $zindex-header;
    border-radius: 6px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);

    &.homepage-header {
        box-shadow: none;
        background: transparent;
        position: absolute;
        width: 100%;
        z-index: $zindex-header;
        top: 0;
    }

    &-content {
        width: $layout-width;
        margin: 0 auto;
        padding: 0 16px 0 0;
        border-radius: 6px;

        // height: 60px;
        height: 52px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        color: #fff;

        &-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        &-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
        }
    }

    &-nav {
        color: #fff;
        display: inherit;
        position: relative;

        a {
            font-size: 14px;
            padding: 0 20px;
            height: 38px;
            line-height: 38px;
            color: #333333;
            display: block;

            // &:first-child,&.ant-dropdown-link{
            //     padding-left: 0;
            // }

            &:hover {
                color: #1070D0;
            }
        }

        &-item {
            &-active {
                a {
                    border-radius: 3px;
                    text-decoration: none;
                    color: #1070D0;
                }
            }

            .red-point {
                position: absolute;
                right: -5px;
                width: 7px;
                top: 3px;
                background-color: #ef4444;
                height: 6px;
                border-radius: 8px;
            }
        }
    }

    &-right-setting {
        padding: 11px 10px;
        display: inline;
        cursor: pointer;
        border-radius: 4px;
        margin-left: 10px;
    }

    .header-alert {
        background-color: #fffbe6;

        .ant-alert {
            width: $layout-width;
            margin: 0 auto;

            .ant-alert-message {
                color: #856404;
            }
        }
    }
}
.nav-container {
    position: relative;

    // background-color: #fff;
    font-size: 14px;

    // font-family: $common-font-family;

    .nav-bar {
        background-color: #e9f4fe;
    }

    .nav-bar-inner {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0;
        display: flex;
        height: 72px;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }

    .header-logo {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
            height: 30px;
            width: 186px;
        }
    }

    .nav-bar-el {
        display: flex;
        justify-content: center;
        margin-left: 46px;

        .nav-bar-list {
            margin: 0;
            display: flex;
            justify-content: center;
            list-style: none;
        }

        .nav-bar-item {
            position: relative;

            .nav-bar-item-title {
                background: transparent;
                border: 0;
                padding: 10px 24px;
                display: flex;
                justify-content: center;
                transition: opacity .25s;
                cursor: pointer;
                position: relative;
                z-index: 2;

                .menu-arrow {
                    transition: $transition-hover;
                }

                &:hover {
                    .menu-arrow {
                        color: #1070D0;
                        transform: rotate(180deg);
                    }
                }

                a {
                    color: #333;

                    &:hover {
                        // font-weight: 500;
                        color: #1070D0;
                    }
                }

                &.active {
                    color: #1070D0;

                    .menu-arrow, a {
                        color: #1070D0;
                    }
                }
            }
        }
    }

    .menu-arrow {
        margin-left: 4px;
        color: #333;
    }


}
.mobile-nav-container {
    .mobile-nav-bar {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        width: 100%;
        
        .menu-icon {
            font-size: 22px;
        }
        .menu-icon-active {
            color: #1070D0;
        }
        .nav-bar-el {
            position: absolute;
            top: 72px;
            background: white;
            width: 100%;
            left: 0;
            margin-left: 0;
            display: block;
            z-index: 999;
            padding: 0 0 16px;
            -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .14);
            box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .14);
            .nav-bar-list {
                flex-direction: column;
                gap: 10px;
                width: 100%;
                justify-content: flex-start;
                .nav-bar-item-title {
                    width: 100%;
                    .item {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        align-items: center;
                    }
                }
                .nav-bart-item-sigle {
                    .nav-bar-item-title {
                        justify-content: flex-start;
                        text-align: left;
                        width: 100%;
                        a {
                            width: 100%;
                        }
                    }
                }
                .nav-level-two {
                    background-color: #f1f3f4;
                    .nav-level-two-tag {
                        background: transparent;
                    }
                    .nav-level-three {
                        background-color: #f1f3f4;
                        .nav-level-three-list {
                            grid-template-columns: 1fr;
                        }
                    }
                    .nav-level-three-group {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        padding: 20px;
                        li {
                            border-width: 0;   
                        }
                    }
                }
            }
        }
    }
    .nav-bar-line {
        border-top: 1px solid #afafaf;
    }
    .op-region {
        .items-center {    
            justify-content: center;
            padding-top: 10px;
        }
    }
}

.nav-dropdown {
    color: #333;

    .menu-arrow {
        transition: $transition-hover;
    }

    &:hover {
        .menu-arrow {
            color: #1070D0;
            transform: rotate(180deg);
        }
    }
}

.nav-overlay {
    .ant-popover-inner {
        box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        background-color: #fff;
    }
}

.nav-level-two {
    display: flex;
    background-color: #fff;
    position: relative;
    position: relative;

    .nav-level-two-list {
        padding: 10px;
        font-weight: 500;

        // &.extend-min {
        //   //min-width: 310px;
        // }

        li {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            padding: 0px 10px;
            margin: 10px 0;
            height: 44px;
            border: 1px solid transparent;
            align-items: center;
            transition: $transition-hover;
            color: #333;
            cursor: pointer;

            .icon-goto {
                visibility: hidden;
                transition: $transition-hover;
            }

            &:hover,
            &.active {
                background: #f9fbfd;
                border-radius: 99px;
                border: 1px solid #f1f4f7;
                color: #1070D0;

                & .icon-goto {
                    visibility: visible;
                }
            }
        }
    }

    &-tag {
        height: 18px;
        background: #f9fbfd;
        border-radius: 2px;
        border: 1px solid #f1f5f9;
        font-weight: 500;
        font-size: 12px;
        margin-left: 5px;
    }
    .nav-level-three {
        width: 520px;
        left: 100%;
        display: flex;
        align-items: stretch;
        background-color: white;
        padding: var(--space-md);
        color: var(--text-color-secondary);
        border-bottom-right-radius: 6px;
        a {
          color: inherit !important;
        }
        .nav-level-three-list {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            width: 100%;
        }
      }
    
      .nav-level-three-group {
        display: flex;
        flex-direction: column;
        &:not(:first-child) {
          // margin-left: 50px;
        }
    
        li {
          padding: 10px;
          cursor: pointer;
          border: 1px solid white;
          &:hover {
            color: var(--text-color-link);
            font-weight: var(--font-weight-tinybold);

            border-radius: 99px;
            border: 1px solid #F1F4F7;
            background: #F9FBFD;
          }
          a,
          span {
            font-size: 14px;
            font-weight: var(--font-weight-normal);
          }
        }
        .hot-wrap {
          position: relative;
          .hot-icon {
            position: absolute;
            right: -20px;
            top: 0;
          }
        }
      }
    .nav-icon {
        width: 32px;
        height: 32px;
        margin-right: 10px;
    }
    .nav-hot-icon {
        margin-left: 5px;
    }
}

.lang-dropdown {
    color: #333;
    padding: 10px 0;

    &:hover {
        color: $link-color;
    }
}

.lang-popover, .nav-popover {
    .ant-menu-vertical {
        border-right: none;
    }

    .ant-popover-inner {
        box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
    }
}

.lang-dropdown-menu {
    padding: 0 4px;
    font-family: PingFangSC-Medium, PingFang SC;

    .ant-menu-item {
        padding: 10px 20px;
        border-radius: 18px;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #333333;

        .lang-item-short {
            color: #B0BCD0;
            margin-left: 6px;
        }

        &:hover {
            border-radius: 99px;
            border: 1px solid #F1F4F7;
            background: #F9FBFD;
        }
    }
}

.common-menu-popover {
    .ant-popover-inner-content {
        padding: 20px;
    }

    .ant-popover-arrow, .ant-popover-arrow-content {
        width: 20px;
        height: 20px;
        box-shadow: none !important;
    }
}

.login-container {
    margin-right: 16px;

    .login-btns {
        display: flex;
        align-items: center;

        button,
        a {
            background-color: transparent;
            border: 0;
            margin-left: 16px;
            color: #0A66C2;
            font-size: 14px;

            &:hover,
            &:active {
                // opacity: .7;
                color: #1070d0;

                // text-decoration: underline;
                cursor: pointer;
                outline: none;
            }

            &:focus {
                outline: none;
            }
        }

        .login-btn-sign-up {
            background-color: #1A7EE3;
            height: 32px;
            border-radius: 18px;
            color: #fff;
            padding: 0 20px;
            min-width: 68px;

            &:hover {
                background-color: #1070d0;
                color: #fff;
            }
        }
    }
}

.login-modal.ant-modal {
    width: 380px;
    height: 600px;

    .login-frame {
        width: 380px;
        height: 600px;

        & > iframe {
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    .ant-modal-header {
        width: 100%;
        background: transparent;
    }

    .ant-modal-body {
        padding: 0;
    }
}

.overlay-container {
    position: relative;

    .overlay-content {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.avatar {
    margin: 0 21px 0 0;
    cursor: pointer;
    padding: 5px 0;

    // position: relative;
    // width: 16px;
    // height: 34px;

    .icon-avatar {
        position: relative;
        top: 2px;

        // left: 0;
        width: 16px;
        height: 18px;
    }

    &:hover {
        .icon-avatar {
            color: $link-color;
        }
    }
}

.login-popover {
    .ant-popover-inner-content {
        padding: 20px 0;
    }
}

.login-info-pop {
    color: #333;
    font-size: 14px;
    font-weight: 500;

    .login-info-pop-line {
        height: 1px;
        width: 100%;
        background-color: #EFF2F5;
        margin: 10px 0;
    }

    .login-info-pop-text {
        span {
            display: block;
        }

        padding: 0 30px;

        .uid {
            color: #999;
            font-size: 12px;
            line-height: 28px;
        }
    }

    .login-info-pop-btns {
        padding: 0 20px;
    }

    .login-info-pop-btn {
        color: #333;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 36px;
        width: 100%;
        border-radius: 18px;
        border: 1px solid transparent;
        text-align: left;
        background: #FFf;
        padding: 0 20px;
        cursor: pointer;

        &:hover {
            border: 1px solid #F1F4F7;
            background: #F9FBFD;
            color: #1070D0;
        }
    }
}

.share-icon {
    transform: translateY(1px);

    svg {
        color: #8EA6BF;
        margin-left: 8px;
        width: 14px;
        height: 14px;
    }
}

.pool-manage-btn {
    margin-right: 30px;
    height: 32px;
    border-radius: 22px;
    border: 1px solid #B0BCD0;
    background-color: transparent;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    a {
        color: #333;
    }

    &.noWatcher {
        &.active, &:hover {
            background-color: #1A7EE3;
            border: none;
            color: #fff;

            a {
                color: #fff;
            }
        }
    }

    &.isWatcher {
        padding-right: 4px;
    }

    .watcherExit {
        padding: 0px 8px;
        margin-left: 8px;
        border-radius: 16px;
        height: 24px;
        background-color: #1A7EE3;
        border: none;
        color: #fff;

        span {
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
}

.login-intro-tip {
    width: 332px;

    .intro-tip-content {
        position: relative;
        padding-top: 4px;
        padding-bottom: 4px;

        .intro-tip-close {
            position: absolute;
            top: -6px;
            right: -7px;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .ant-popover-content {
        background: #1A7EE3;
        box-shadow: 0px 12px 12px 0px rgba(16, 112, 208, 0.1);
        border-radius: 6px;

        .ant-popover-arrow {
            width: 12px;
            height: 12px;
        }

        .ant-popover-arrow-content {
            top: 6px;
            width: 12px;
            height: 12px;
            background: #1A7EE3;
        }

        .ant-popover-inner {
            background: #1A7EE3;
        }

        .ant-popover-inner-content {
            color: #fff;
            font-size: 14px;
        }
    }
}

.nav-tag-badge {
    position: absolute;
    top: -12px;
    left: 60px;
    width: max-content;
    height: 22px;
    font-size: 12px !important;
    line-height: 22px;
    padding: 0 10px;
    background: radial-gradient(circle at 100% 100%, #f39164 0, #fcb594 100%), #1470ce;
    border-radius: 16px;
    border-bottom-left-radius: 12px;
    color: #fff;
    z-index: 3;
    &:after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 1px;
        border: 7px solid transparent;
        border-left-color: #fcb594;
        transform: rotate(-8deg)
    }
    span {
        font-size: 12px !important;
    }
}