.group-entry {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  background-color: #5080af;
  text-align: center;
  .wordContaner {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    padding: 8px;
    text-align: left;
  }
  .bg-white {
    background-color: white;
  }
  .about {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    color: #fff !important;
    &:hover {
      color: #0a66c2 !important;
      .bg-white {
        background-color: #0a66c2 !important;
      }
    }
  }
  .markeyCode {
    float: right;
    margin-right: 20px;
    padding: 0 3px;
    border: 1px solid rgba($color: #fff, $alpha: 0.5);
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }
}

.newDomainAlert {
  user-select: text;
  p{
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 30px;
  }
  .newDomainAlert-close {
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
    color: #333;
    font-size: 16px;
  }
  .newDomainAlert-title {
    user-select: text;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    .icon {
      color: red;
      font-size: 24px;
    }
  }
  .newDomainAlert-desc {
    user-select: text;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .newDomainAlert-btn-wrap {
    margin: 24px 0 5px 0;
    text-align: center;
    min-width: 120px;
    .newDomainAlert-btn {
      min-width: 180px;
      height: 40px;
    }
  }
}
// responsive
.xs-hide {
}