
@import "../../../styles/base/variable.scss";
// btc-collapse
.btc-collapse-container{
  background: #fff;
  margin-top:$margin-xlg;
  .btc-collapse-header{
    position: relative;
    border-top:1px solid $line-color;
    color:$primary-color;
    word-spacing: .1em;
    text-align: center;
    .collapse-title-wrap{
      position: absolute;
      top:-12px;
      text-align: center;
      width: 100%;
      .collapse-title{
        display: inline-block;
        background-color: #fff;
        padding:0 16px;
        cursor: pointer;
        user-select: none;
       
      }
      .collapse-toggle{
        color:$primary-color;
        margin-left: $margin-sm;
        margin-right: $margin-sm;
      }
    }
   
   
  }
  .btc-collapse-content{
    padding:$padding-xlg 0;

    &.btc-collapse-expand{
      display:block;
    }
    &.btc-collapse-curlup{
      display: none;
    }

  }
}