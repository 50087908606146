@import '../../styles/base/upgrade-variable';

$primary-color:#1A7EE3;


%line {
    border-bottom: 1px solid #F1F4F7;
}

.secondaryText {
    color: #8EA6BF !important;
}

.accountPop {
    width: 600px;
    border-radius: 8px;
    background: #F1F4F7;
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.06);
    min-height: 630px;
    color: $text-color;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;


    @media screen and (min-width: 850px) and (max-height: 768px) {
        min-height: 560px;
    }

    // 半透明遮罩
    &.hasContent::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 40px;
        z-index: 9;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);

    }


    :global {
        ::-webkit-scrollbar {
            width: 5px;
            background-color: #E6E6E6;
            // display: none;
        }

        ::-webkit-scrollbar-thumb {
            width: 5px;
            background-color: #AFAFAF;
            border-radius: 8px;
        }

    }

    // &.scrolling {
    //     ::-webkit-scrollbar {
    //         display: block;
    //     }
    // }


    .panel {
        padding: 8px 16px 16px 16px;
        background-color: #fff;
        transition: all 0.7s;
        position: relative;
        height: 98px;
        z-index: 2;

        &.shadow {
            box-shadow: 0px 8px 12px 0px rgba(26, 126, 227, 0.08);
        }

        h2 {
            line-height: 2rem;
        }
    }

    .container {
        overflow-y: overlay;
        flex: 1;
        height: 630px;
        position: relative;
        z-index: 1;
        padding-top: 16px;

        @media screen and (min-width: 850px) and (max-height: 768px) {
            height: 460px;
        }

        .containerInner {
            padding: 0 22px 24px 16px;
            height: inherit;
        }




    }

    .accountLocator {
        position: absolute;
        top: 280px;
        right: 8px;
        z-index: 3;
        width: 32px;
        height: 32px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        box-shadow: 0px 2px 12px 0px rgba(26, 126, 227, 0.16);
        cursor: pointer;
        visibility: hidden;
        transition: all .5s;


        &:hover {
            box-shadow: 0px 2px 12px 0px rgba(26, 126, 227, 0.4);
        }

        svg {
            width: 24px;
            height: 24px;
        }

        @media screen and (min-width: 850px) and (max-height: 768px) {
            top: 200px;
        }
    }


    .group {
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #E3EDFF;
        padding: 0;
        margin-bottom: 10px;

        &:last-child {
            &::after {
                content: '';
                width: 1px;
                height: 40px;
                position: absolute;
                background: transparent;
            }
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            height: 56px;
            @extend %line;

            h3 {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 0;
            }
        }

        .footer {
            padding: 8px 16px 8px 32px;
            border-top: 1px solid #F1F4F7;
        }


        &.active {
            border: 1px solid $primary-color;

            .header {
                h3 {
                    color: #0A66C2;
                }
            }
        }



    }

    .coinItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        width: 100%;
        padding: 16px 16px 16px 14px;
        line-height: 16px;
        height: 50px;
        position: relative;
        border-left: 2px solid transparent;
        transition: all 0.3s ease;
        @extend %line;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background: #F9FBFD;
            border-left: 2px solid #1A7EE3;
        }


        &.isSibling:first-child {
            border-bottom: 1px dashed #F1F4F7;
            position: relative;

            &::before {
                content: '';
                width: 32px;
                height: 1px;
                background: #fff;
                position: absolute;
                bottom: -1px;
                left: 0;
            }
        }



        &.isCurrentLogin {
            background: $primary-color;
            color: #fff;
            font-weight: 500;
        }

        &>span {
            flex: 1;
            text-align: right;
        }


        .isSha256 {
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background: #D8DEE3;

            &.currentSha256Symbol {


                background: #3198FF;

            }
        }

        .coinName {
            display: flex;
            align-items: center;
            flex: 0 0 110px;
            padding-left: 16px;


            img {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }
        }

        .hashrate {
            display: inline-flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            padding-right: 20px;

        }

        .miners {
            color: #8EA6BF;
            display: inline-flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            padding-right: 20px;
        }

        .miningStatus {
            text-align: center;
            flex: 0.92;
            font-weight: 400;
        }
    }

}


.groupLabel {
    height: 64px;
    background: #F6F9FC;
    border-radius: 4px;
    border: 1px solid #F1F4F7;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #222549;

    .labelItem {
        flex: 1;
        line-height: 1.25rem;
        position: relative;
        display: flex;
        flex-direction: column;

        &:not(:first-child) {
            padding-left: 48px;

            &::before {
                content: '';
                height: 36px;
                width: 1px;
                background: #DFE4E8;
                position: absolute;
                top: 2px;
                left: 24px;
            }

        }

        .lableTitle {
            font-weight: 500;
            margin-bottom: 6px;
        }

    }
}

.coinSelectorItem {
    min-width: 66px;
    height: 66px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #D8DEE3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    color: #222549;
    padding: 10px;

    img {
        width: 24px;
        height: 24px;

    }

    span {
        margin-top: 8px;
        line-height: 1;
    }

    &:hover,
    &:active,
    &:focus,
    &.selectorActive {
        background-color: #F6F9FC;
        border: 1px solid #1070D0;
        color: #1070D0;
    }
}

.regionSelectorItem {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #D8DEE3;
    height: 40px;
    margin-right: 10px;

    .regionAbbr {
        background-color: #1070D0;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-right: 8px;
        padding: 3px 8px;
        letter-spacing: .1em;
        font-size: 11px;
        font-weight: 500;
        border-radius: 2px;
        background: #1A7EE3;

        .ultr01Font {
            transform: scale(0.83);


        }


    }

    &:hover,
    &:active,
    &:focus,
    &.selectorActive {
        background-color: #F6F9FC;
        border: 1px solid #1070D0;
        color: #1070D0;
    }
}

.form {
    color: #222549;

    .formItem {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 32px;

        label {
            margin-bottom: 16px;
            font-weight: 500;

        }
    }

    .nameItem {
        .nameInput {
            width: 300px;
        }

    }

}