@import "../../styles/base/variable";


.hot-spot {
    position: absolute;
    right: 26px;
    width: 7px;
    top: 3px;
    background-color: #ef4444;
    height: 6px;
    border-radius: 8px;
}

.nav-with-menu {
    display: flex;
    align-items: center;

    &:hover {
        .nav-menu-arrow {
            transform: rotate(180deg);

        }
    }

    .nav-menu-arrow {
        margin-left: 4px;

    }
}

.utc-time {
    color: #333;
    padding:0 16px;
    display: flex;
    align-items: center;
}

