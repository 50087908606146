@import '../../../styles/base/variable.scss';

$btn-bg-color-primary: #1A7EE3;


.btc-button-container {
  display: inline-block;

  &:not(:first-child) {
    margin-left: 2px;
  }

  &.upgrade {

    .ant-btn-default.ant-btn {
      background-color: $btn-bg-color-primary;
      color: #fff;
    }

    .ant-btn-ghost {
      border: 1px solid $btn-bg-color-primary;
      color: $btn-bg-color-primary;
      background-color: transparent;
      box-shadow: none;



      &:focus {
        color: $btn-bg-color-primary;
        background-color: transparent;
      }

      &:hover {
        color: #fff;
        background-color: $btn-bg-color-primary;
      }



    }

  }


  .ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    font-size: 14px;
    color: #fff;
    background-color: $btn-bg-color-primary;
    box-shadow: none;
    border: none;
    padding: 0 24px;

    &:focus {
      color: #fff;
      background-color: $btn-bg-color-primary;
    }

    // &.ant-btn-default {
    //   background-color: #fff;
    //   color: #333;

    // }

    &.ant-btn-sm {
      padding: 0 16px;
    }

    &.ant-btn-lg {
      padding: 0 32px;
    }

    &:hover {
      background-color: #1070D0;
      color: #fff;
      // border: 1px solid transparent;
    }

    &.ant-btn-link {
      padding: 0;
      color: #1A7EE3;
      border-color: transparent;
      background: transparent;
      box-shadow: none;
    }

  }





  .ant-btn-ghost {
    border: 1px solid $btn-bg-color-primary;
    color: $btn-bg-color-primary;
    background-color: transparent;
    box-shadow: none;

    &:hover {
      color: #fff;
    }

    &:focus {
      color: $btn-bg-color-primary;
      background-color: transparent;
    }



  }

  .ant-btn-dashed {

    &,
    &:active,
    &:focus {
      background-color: #fff;
      border: 1px dashed #8EA6BF;
      color: #8EA6BF;
    }

    &:hover {
      border: 1px dashed #1A7EE3;
      color: #1070D0;
      background-color: #fff;
    }
  }


  &.simple {

    .ant-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background: #F1F4F7;
      border-radius: 3px;
      border: none;
      padding: 0;
      box-shadow: none;
      color: $btn-bg-color-primary;

      &:hover {
        background: $btn-bg-color-primary;
        color: #fff;
      }
    }
  }

  &.secondary-link {


    .ant-btn-link {
      color: #8EA6BF;
      padding: 0;
      background-color: transparent;

      &:hover {
        color: #1070D0;
        ;

      }
    }
  }


}