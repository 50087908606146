// Color
$primary-color: #3c78c2;
$secondary-color: #7799BB;
$page-color: #e6e9ee;
$line-color: #e6e9ee;
$link-color: #0A66C2;
$link-hover-color: lighten($primary-color, 10%);
$active-color: lighten($primary-color, 10%);

// Text Color
$text-color-default: #222549;
$text-color-primary: #222549;
$text-color-secondary: #8EA6BF;
$text-color-tertiary: #999;
$text-color-disable: rgba(0, 0, 0, 0.25);
$text-color-active: #1070D0;

// Status Color
$success-color: #0BB168;
$warning-color: #faad14;
$error-color: #F24646;
$important-color: #CC3300; // 重要提醒
$increase-color: #F24646; // 增长
$decline-color: #0BB168; // 下降

$disable-color: rgba(0, 0, 0, 0.25);

// Component Color
$chunk-color: #F3F4F7; // 块颜色
$card-header-bg: #F3F4F7;
$list-hover-bg: #F3F4F7;

// Dimension
$layout-width: 1200px;
$size-pagemax-width: 1200px;

$dimension-xxlg: 40px;
$dimension-xlg: 32px;
$dimension-lg: 24px;
$dimension-md: 16px;
$dimension-sm: 8px;
$dimension-xs: 4px;
$dimension-xxs: 2px;

$padding-xlg: $dimension-xlg;
$padding-lg: $dimension-lg;
$padding-md: $dimension-md;
$padding-sm: $dimension-sm;

$margin-xlg: $dimension-xlg;
$margin-lg: $dimension-lg;
$margin-md: $dimension-md;
$margin-sm: $dimension-sm;

$border-radius-base: 4px;
$border-radius-sm: 2px;

// Component Size
$card-header-height: 40px;
$card-header-height-sm: 32px;
$card-padding: 8px;
$card-padding-sm: 6px;
$card-padding-lg: 16px;

$modal-header-height: 56px;
$modal-footer-height: 80px;
$modal-padding-left: 24px;
$modal-padding-right: 16px;

// Font Size
$font-size-base: 14px;
// $font-size-xlg: $font-size-base + 6px;
// $font-size-lg: $font-size-base + 2px;
$font-size-lg: $font-size-base + 2px;
$font-size-xlg: $font-size-base + 4px;
$font-size-xxlg: $font-size-base + 6px;
$font-size-sm: 12px;
$font-size-xs: 10px;

// Icon Size
$icon-size: 16px;
$icon-size-lg: 24px;
$icon-size-xlg: 36px;

// ZIndex
$zindex-header: 1000;

$common-font-family: Helvetica Neue, Tahoma, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica, Arial, sans-serif, Roboto, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

// Transition
$transition-ease-in: all 0.3s cubic-bezier(0.4, 0, 1, 1);
$transition-ease-out: all 0.3s cubic-bezier(0, 0, 0.2, 1);
$transition-ease-in-out: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

//#region History

// media query break points
$breakpoints: (
  xs: 420px,
  mxs: 430px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xlg: $size-pagemax-width
) !default;

// //h1,h2,h3,h4,h5
// $heading-color: rgba(#000, 85%);

// $body-background: rgba(240, 242, 245, 1);
// $font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB",
//     "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
//     "Segoe UI Symbol";

// $font-size-base: 14px;
// $line-height-base: 1.5;

// //
// // Color system
// //

// $white:    #ffffff !default;
// $gray-100: #f8f9fa !default;
// $gray-300: #e4e7ed !default;
// $gray-600: #999999 !default;
// $gray-700: #6A6A6A !default;
// $gray-800: #333333 !default;
// $gray-850: #282828 !default;
// $gray-900: #222222 !default;
// $gray-950: #0A0A0A !default;
// $black:    #000 !default;
// $transparent:    transparent !default;

// $blue:    #1890ff !default;
// $red:     #F5222D !default;
// $orange:  #FD7E14 !default;
// $yellow:  #FAAD14 !default;
// $green:   #52C41A !default;
// $teal:    #20C997 !default;
// $cyan:    #17A2B8 !default;

// $primary:       $blue !default;
// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

// $theme-colors: () !default;
// // stylelint-disable-next-line scss/dollar-variable-default
// $theme-colors: map-merge(
//   (
//     "primary":    $primary,
//     "secondary":  $secondary,
//     "success":    $success,
//     "info":       $info,
//     "warning":    $warning,
//     "danger":     $danger,
//     "light":      $light,
//     "dark":       $dark
//   ),
//   $theme-colors
// );

// $link-hover-color: #40a9ff;
// $link-active-color: #096dd9;
// $link-decoration: none;
// $link-hover-decoration: none;

// //text-color
// $text-color: rgba(0, 0, 0, 0.65);
// $text-color-caption: rgba(0, 0, 0, .45);

// //

// // Fonts
// //
// // Font, line-height, and color for body text, headings, and more.

// // stylelint-disable value-keyword-case
// $font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// $font-family-base:            $font-family-sans-serif !default;
// $font-size: 14px;
//#endregion
